import React from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import Fetch from "./Fetch";
import Video from "./Video";
import { useLoading } from "./LoadingContext";

const VideoScreen = () => {
  let params = useParams();
  const videoID = params.videoID;
  const { updateLoading, setVideoReady } = useLoading();

  const updateVideoContext = () => {
    setVideoReady(true);
    updateLoading();
  };

  if (videoID.length === 0) return <h1>Enter a video to watch</h1>;
  return (
    <Fetch
      requestData={{
        uri: "/convert",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ videoID })
      }}
      method="POST"
      renderSuccess={(data) => (
        <Video {...{ data, videoID, updateVideoContext }} />
      )}
      loadingFallback={<Loading />}
      renderError={() => {
        updateVideoContext();
        return (
          <h1 className="err">
            Whoops! Looks like something went wrong... please try again later
          </h1>
        );
      }}
    />
  );
};

export default VideoScreen;
