import React, { useState, useEffect } from "react";
import { useLoading } from "./LoadingContext";

const getID = (url) => {
  if (url.indexOf("youtu.be") === -1) {
    return url.substring(
      url.indexOf("v=") + 2,
      url.indexOf("&") > url.indexOf("v=") ? url.indexOf("&") : url.length
    );
  } else {
    const idStart = url.indexOf(".be/") + 4;
    console.log(url.charAt(idStart));
    return url.substring(
      idStart,
      url.indexOf("?") > idStart ? url.indexOf("?") : url.length
    );
  }
};

const VideoForm = () => {
  const [url, setURL] = useState("");
  const { loading, updateLoading, setVideoReady } = useLoading();
  updateLoading();

  useEffect(() => {
    console.log("rendering VideoForm component loading");
    console.log(loading);
  });

  const inputClasses =
    "p-1.5 mt-6 w-7/12 rounded-lg border-2 relative m-auto border-purple-900 border-dashed sm:w-5/12 sm:p-2 md:text-lg md:p-2.5 xl:text-xl xl:p-3.5";
  const buttonClasses =
    "gradient w-7/12 font-bold text-white py-1.5 px-3 mt-4 border-2 rounded-lg border-purple-900 border-solid sm:w-5/12 sm:p-2 md:text-lg md:p-2.5 xl:text-xl xl:p-3.5";
  const waitMessage =
    "This might take a minute or two depending on the length of your video...";
  const defaultMessage = "Enter a youtube link below";

  return (
    <section className="text-center">
      <h3 className="font-bold text-xl m-6 mb-0 gradient-text lg:text-2xl lg:mt-16">
        {loading ? waitMessage : defaultMessage}
      </h3>
      <input
        onChange={(e) => setURL(e.target.value)}
        type="text"
        placeholder="paste link here"
        className={loading ? "hidden" : inputClasses}
      />
      <br />
      <button
        className={loading ? "hidden" : buttonClasses}
        onClick={() => {
          console.log("id:", url, getID(url));
          setVideoReady(false);
          url.length !== 0
            ? window.open("/" + getID(url), "_self")
            : alert("Please enter a URL to stream");
        }}
      >
        Watch without music!
      </button>
    </section>
  );
};

export default VideoForm;
