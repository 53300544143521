import React from "react";
import Logo from "./Logo";
import ShareButton from "./ShareButton";

const NavBar = () => {
  return (
    <nav className="p-2.5 px-2 sm:px-3 md:px-4 lg:px-6 xl:p-7 flex flex-row h-min rounded-b-lg text-pink-600 bg-gray-50">
      <a
        className="flex-auto h-min hover:underline p-0"
        href="https://switchoffmusic.com"
      >
        <div className="absolute inline-flex animate-ping border-solid border-4 border-purple-900 bg-transparent p-px rounded-full mt-1.5 ml-1.5 h-7 w-7" />
        <div className="inline-block relative z-10 w-10 h-10 p-2 align-middle rounded-full gradient text-center">
          <Logo width={90} height={90} />{" "}
        </div>
        <span className="gradient-text align-middle ml-2 m-auto inline font-bold text-sm lg:text-xl">
          SwitchOffMusic
        </span>
      </a>
      <div className="w-min table">
        <ShareButton />
      </div>
    </nav>
  );
};

export default NavBar;
