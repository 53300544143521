import React from "react";
import { FaRegShareSquare } from "react-icons/fa";
import { useLoading } from "./LoadingContext";

const ShareButton = () => {
  const { videoReady } = useLoading();
  return (
    <div className="w-full float-right">
      <button
        className={`align-middle mt-1 float-right flex-initial w-min text-center text-white p-1.5 px-4 rounded-full text-sm
            font-semibold tracking-wider gradient border-2 border-purple-900 border-double
            sm:text-base sm:tracking-wide sm:font-bold sm:px-4 md:text-lg md:px-5 xl:text-xl xl:p-2 xl:px-7`}
        onClick={() => {
          try {
            navigator.share({ url: window.location });
          } catch (err) {
            prompt("Copy shareable link below", window.location);
          }
        }}
      >
        <div className="flex w-max">
          <span className="m-0 p-0">
            {videoReady ? "Share Video" : "Share Website"}
          </span>
          <FaRegShareSquare width="20" height="20" className="mt-1 ml-1" />
        </div>
      </button>
    </div>
  );
};

export default ShareButton;
