import React, { useState, useContext, createContext } from "react";
import { useParams } from "react-router-dom";

const Context = createContext();

export const LoadingContext = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  console.log("set loading ", loading);

  const onHomePage = () => {
    let params = useParams();
    console.log("on home page: ", params.videoID == null ? true : false);
    return params.videoID == null ? true : false;
  };

  const getNewLoading = () => {
    if (onHomePage() || videoReady) {
      return false;
    }
    return true;
  };

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        updateLoading: () => setLoading(getNewLoading()),
        setVideoReady,
        videoReady
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLoading = () => useContext(Context);
