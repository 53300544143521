import "./App.css";
// import "video-react/dist/video-react.css"; // import video-react css
import React from "react";
import { Switch, Route } from "react-router-dom";

import VideoScreen from "./components/VideoScreen";
import Hero from "./components/Hero";
import Whoops404 from "./components/Whoops404";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import VideoForm from "./components/VideoForm";

function App() {
  return (
    <>
      <NavBar />
      <div className="flex-grow mb-24 md:mb-28 xl:mb-32">
        <Switch>
          <Route path="/:videoID">
            <Hero />
            <VideoScreen />
            <VideoForm />
          </Route>
          <Route path="/">
            <Hero />
            <VideoForm />
          </Route>
          <Route path="*">
            <Whoops404 />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
}

export default App;
