import React, { useState, useEffect } from "react";

const postRequest = (uri, headers, body) =>
  fetch(uri, { headers, body, method: "POST" });
const getRequest = (uri, headers) => fetch(uri, { headers, method: "GET" });

function useFetch(uri, headers, body, method) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!(uri && headers && body && method)) return;
    const request = method === "GET"
      ? getRequest(uri, headers)
      : postRequest(uri, headers, body)
    request
      .then((data) => data.json())
      .then(setData)
      .then(() => setLoading(false))
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [uri]);

  return {
    loading,
    data,
    error
  };
}

export default function Fetch({
  requestData,
  method,
  renderSuccess,
  loadingFallback = <p>loading...</p>,
  renderError = (error) => <pre>{JSON.stringify(error, null, 2)}</pre>
}) {
  const { loading, data, error } = useFetch(
    requestData.uri,
    requestData.headers,
    requestData.body,
    method
  );

  if (loading) return loadingFallback;
  if (error) return renderError(error);
  if (data) return renderSuccess({ data });
}
