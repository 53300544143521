import React from "react";
// import isVideoPlaying from "./isVideoPlaying"

// const getPosition = () => isVideoPlaying() ? "relative" : "absolute";

const Footer = () => {
  return (
    <footer className="h-18 -mt-16 text-center p-5 text-white font-bold gradient sm:p-6 sm:text-lg md:text-xl md:p-5 lg:p-7">
      <p>
        ©2021{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hammaadmemon.com"
          className="underline"
        >
          Hammaad Memon
        </a>
      </p>
    </footer>
  );
};

export default Footer;
