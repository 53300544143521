import React from "react";
import { FiArrowDownCircle } from "react-icons/fi";

const Hero = () => {
  return (
    <section className="relative h-64 gradient p-12 md:py-28 md:h-auto lg:py-36">
      <h1 className="text-gray-50 text-4xl align-top sm:align-middle font-black leading-normal sm:tracking-wider md:tracking-widest mt-4 text-center sm:text-5xl md:text-6xl md:mb-8 lg:align-top lg:mt-auto lg-text-6xl xl:text-8xl">
        Say Hello to Music Free Streaming
      </h1>
      <FiArrowDownCircle
        color="white"
        size="28"
        className="absolute bottom-8 left-0 right-0 m-auto animate-bounce"
      />
    </section>
  );
};

export default Hero;
