import React from "react";
import BarLoader from "react-spinners/BarLoader";

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
`;

const Loading = () => {
  console.log("loading");
  return (
    <section className="text-center font-bold p-2 mt-4 text-xl md:text-2xl">
      <p className="bg-clip-text gradient md:text-2xl">Processing</p>
      <br />
      <div className="w-7/12 relative m-auto md:w-6/12">
        <BarLoader color="fuchsia" loading={true} css={override} />
      </div>
    </section>
  );
};

export default Loading;
