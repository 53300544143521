import React, { useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Loading from "./Loading";

export default function Video({ data, videoID, updateVideoContext }) {
  const status = data.data.status;
  const url = "/watch?videoID=" + videoID;
  const player = useRef();
  const playerWrapper = useRef();
  updateVideoContext();

  useEffect(() => {
    console.log("rendered video");
    if (player.current) {
      playerWrapper.current.scrollIntoView({
        behavior: "smooth"
      });
      player.current.load();
      player.current.currentTime = 0;
      player.current.play();
    }
  }, []);

  if (data == undefined) return null;
  if (status === "failure")
    return (
      <h1 className="err">Something went wrong... please try again later</h1>
    );
  if (status === "processing")
    return (
      <h1 className="err">
        Video is being processed, please check back again later
      </h1>
    );

  return (
    <>
      <div
        id="video"
        ref={playerWrapper}
        className="p-0.5 mt-8 w-11/12 rounded-lg border-4 border-red-900 relative m-auto lg:w-8/12 "
      >
        <ReactPlayer
          url={url}
          playing={true}
          controls={true}
          pip={true}
          fallback={<Loading />}
          width="100%"
          height="100%"
        ></ReactPlayer>
      </div>
    </>
  );
}
